@media only screen and (max-width: 1024px) {
    .header {
        left: unset;
        right: -100%;
        z-index: 55;
    }

    .mHeader-wrapper {
        display: flex;
    }

    .mainContent.Menuopen {
        -webkit-filter: blur(8px);
        filter: blur(8px);
    }

    .mainContent {
        width: 100%;
        top: 60px;
        left: 0;
        height: calc(100vh - 70px);
    }

    .sideBarOpener {
        top: unset;
        top: unset;
        bottom: 10px;
        width: 40px;
        height: 40px;
    }

    .siderbar.open {
        right: 45px;
        height: calc(100vh - 70px);
        top: 50px;
    }

    .header.open {
        right: 0;
        max-height: calc(100vh - 70px);
        top: 50px;
    }

    .conatct-link {
        width: 100%;
        margin-bottom: 15px;
    }

    .form-cover {
        width: 80%;
    }
}

@media only screen and (max-width: 768px) {
    section.heroarea {
        flex-direction: column-reverse;
    }

    .hero-bg {
        height: 40vh;
        width: 100%;
        overflow: hidden;
    }

    .hero-text {
        margin-top: 25px;
        width: 100%;
    }

    .doList {
        width: 100%;
    }

    .testiList {
        width: 100%;
        margin-bottom: 25px;
    }

    .pricing-list {
        width: 100%;
        margin-bottom: 25px;
    }

    .fatc-list {
        width: 100%;
        margin-top: 25px;
    }

    .footer {
        text-align: center;
    }

    .footer-copyright {
        float: unset;
        margin-top: 15px;
    }

    .resume-first {
        width: 100%;
    }

    .resume-sec {
        width: 100%;
    }

    .social-links li {
        margin: 0 15px;
        text-align: center;
    }

    .watToDo,
    .testmonial,
    .fact,
    .priceing,
    .page-sction {
        padding: 25px;
    }

    .hero-text {
        padding-left: 25px;
        padding-right: 25px;
    }

    .form-cover {
        width: 100%;
    }
}