:root {
    --primaryBgc: #20262c;
    --secBgc: rgba(0, 0, 0, 0.07);
    --primaryText: #fff;
    --secText: #9e9e9e;
    --gray-dark: #343a40;
    --fade-dark: #475c70;
    --header-dark: #31373d;
    --btnPrimary: #007ced;
}

/* Scrollbar Styling */
::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: var(--gray-dark);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: var(--secText);
}

/* end */
body {
    background-color: var(--primaryBgc);
}

ul {
    list-style: none;
}

.mainBody {
    position: relative;
    height: 100vh;
    overflow: hidden;
}

.header {
    position: absolute;
    width: 320px;
    top: 10px;
    left: 10px;
    background-color: var(--header-dark);
    max-height: calc(100vh - 20px);
    min-height: calc(100vh - 20px);
    transition: all 0.3s linear;
}

.headerBox {
    background-color: var(--secBgc);
    padding: 40px;
    text-align: center;
}

a {
    text-decoration: none;
}

a.pName {
    margin: 15px 0;
    display: block;
}

a.pName h3 {
    font-size: 24px;
    color: var(--primaryText);
    font-weight: 700;
}

a.pSubName h5 {
    font-size: 14px;
    font-weight: 400;
    margin: 5px 0 0;
    color: var(--secText);
}

.headerBox img {
    width: 100%;
}

.profileBlock {
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
}

.profileBlock {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 50%;
    background: #000;
    text-align: center;
    margin: 0 auto;
    border: 4px solid var(--secBgc);
    transition: all 0.5s linear;
}

.profileBlock:hover {
    border-color: var(--primaryText);
}

.headerBox img {
    width: 85px;
    object-fit: cover;
    object-position: center center;
}

ul.headerLists {
    padding: 60px;
    text-align: right;
    list-style: none;
}

ul.headerLists li {
    padding-right: 0;
}

ul.headerLists li a {
    position: relative;
    color: var(--primaryText);
    font-size: 15px;
    font-weight: 600;
    padding: 15px 0;
    display: block;
}

li.headerLists a:after {
    content: "";
    height: 2px;
    width: 50px;
    background: var(--secText);
    position: absolute;
    left: 0;
    top: 50%;
    opacity: 0;
    transform: translateY(-50%);
    transition: all 0.2s linear;
}

li.headerLists a.active:after {
    left: 15px;
    opacity: 1;
}

li.headerLists a:hover:after {
    left: 15px;
    opacity: 1;
}

.mainContent {
    width: calc(100% - 430px);
    height: calc(100vh - 20px);
    position: absolute;
    left: 340px;
    top: 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: all 0.3s linear;
}

.mainContent.sidebaropen {
    -webkit-filter: blur(8px);
    filter: blur(8px);
}

section.heroarea {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.hero-text {
    color: #fff;
}

.hero-bg {
    height: 80vh;
    width: 50%;
    overflow: hidden;
    position: relative;
}

.hero-text {
    color: #fff;
    padding-left: 55px;
    padding-right: 30px;
    width: 50%;
}

h5.heroSubHeading {
    display: block;
    font-size: 16px;
    font-weight: 600;
    margin: 5px 0 0;
    color: var(--secText);
}

.btn.btn-cs-primary {
    background: var(--btnPrimary);
    border-radius: 0;
    padding: 10px 30px;
    color: var(--primaryText);
    transition: all 0.2s linear;
    margin-right: 5px;
}

.herobtn .btn {
    margin-top: 15px;
}

.heroTitle {
    font-size: 45px;
    font-weight: 600;
    line-height: 1.3;
    margin-top: 0;
    margin-bottom: 20px;
}

.heroTitle span {
    font-weight: 400;
}

p.heroText {
    font-family: "Poppins", Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 0;
    font-style: normal;
    line-height: 1.60em;
    color: #a6a7a8;
}

.herobtn {
    margin-top: 40px;
}

.btn.btn-cs-primary:hover,
.btn.btn-cs-sec:hover {
    background: #444;
}

.btn.btn-cs-sec {
    background: rgba(255, 255, 255, .1);
    border-radius: 0;
    padding: 10px 30px;
    color: var(--primaryText);
    transition: all 0.2s linear;
}

.heroImg {
    height: calc(100% + 50px);
    width: calc(100% + 50px);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.2s ease-in-out;
}

.footer {
    display: block;
    padding: 15px 50px;
    background: var(--header-dark);
}

.social-links {
    list-style: none;
    margin: 0;
    padding: 0;
}

.social-links li {
    display: inline-block;
    margin-right: 25px;
}

.social-links li a {
    color: #aaa;
    text-decoration: none;
    line-height: 21px;
    font-size: 15px;
}

.footer-social {
    display: inline-block;
}

.footer-copyright {
    font-size: 15px;
    color: #aaa;
    float: right;
}

.section-title {
    display: block;
}

.section-title h2 {
    position: relative;
    display: inline-block;
    font-size: 21px;
    margin: 0 0 8px;
    padding-bottom: 6px;
    z-index: 1;
    color: var(--primaryText);
}

.watToDo,
.testmonial,
.fact,
.priceing,
.page-sction {
    padding: 50px 30px 50px 55px;
}

.section-title h2:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 100%;
    background: rgba(255, 255, 255, .09);
}

.section-title h2:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 25px;
    background: var(--btnPrimary);
}

.doLists {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 25px;
}

.doList {
    width: 50%;
    position: relative;
    margin-top: 15px;
}

.info-icon-area {
    position: absolute;
    top: 0;
    left: -5px;
    display: inline-block;
    background: rgba(255, 255, 255, .1);
    padding: 10px 0;
    width: 55px;
    border-radius: 5px;
    text-align: center;
    color: #155dff;
    font-size: 23px;
}

.info-content-area {
    width: calc(100% - 60px);
    float: right;
    padding-right: 25px;
}

.info-heading {
    margin: 10px 0;
    font-size: 20px;
    color: var(--primaryText);
}

p.info-text {
    font-family: "Poppins", Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 0;
    font-style: normal;
    line-height: 1.60em;
    color: #a6a7a8;
}

.testiLists {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.testiList {
    width: calc(50% - 25px);
    background: rgba(255, 255, 255, .05);
    border-radius: 10px;
    padding: 10px;
    position: relative;
}

.testiLists {
    padding: 25px;
    margin-top: 50px;
}

.testi-img {
    position: absolute;
    top: -40px;
    left: -40px;
    display: inline-block;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    background: #000;
}

.testi-img img {
    width: 76%;
    object-fit: cover;
    object-position: top;
}

.testi-text {
    padding: 25px 30px;
}

.testi-text p {
    font-style: italic;
    font-family: "Poppins", Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 0;
    line-height: 1.60em;
    color: #a6a7a8;
}

.testi-heading {
    color: var(--primaryText);
    font-size: 15px;
    font-weight: 700;
}

.testi-sub-heading {
    color: var(--btnPrimary);
    font-size: 14px;
    font-weight: 400;
}

.fatc-lists {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
}

.fatc-list {
    width: calc(33.3% - 20px);
    margin-top: 40px;
}

.fatc-list-icon {
    background: rgba(255, 255, 255, .1);
    padding: 35px 0;
    width: 100px;
    text-align: center;
    color: #fff;
    font-size: 30px;
    display: inline-block;
}

.fatc-list-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #ffffff6b;
    border-radius: 10px;
}

.fatc-list-cont h3 {
    color: var(--btnPrimary);
}

.fatc-list-cont span {
    color: var(--primaryText);
    font-size: 17px;
    font-weight: 700;
}

.fatc-list-cont {
    padding: 0 35px;
    width: calc(100% - 100px);
}

.pricing-lists {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 40px;
}

.pricing-list {
    width: calc(33.3% - 20px);
    padding: 40px;
    border-color: rgba(255, 255, 255, .07);
    background-color: rgba(255, 255, 255, .05);
    border-radius: 10px;
    transition: all 0.2s linear;
}

.pricing-list:hover {
    /* -webkit-box-shadow: 0 0 24px rgb(255 255 255 / 14%);
    -moz-box-shadow: 0 0 24px rgba(255, 255, 255, .14);
    -o-box-shadow: 0 0 24px rgba(255, 255, 255, .14);
    box-shadow: 0 0 24px rgb(255 255 255 / 14%); */
    transform: translateY(-20px);
}

.pricing-list h4 {
    color: var(--primaryText);
    margin-bottom: 15px;
}

.pricing-list .price {
    color: var(--primaryText);
    font-size: 40px;
    font-weight: 700;
    padding: 20px 0;
}

.pricing-list .price .priceSmal {
    font-size: 15px;
    font-weight: 400;
    color: var(--secText);
}

.pricing-list .program {
    display: block;
    color: var(--secText);
    padding: 10px 0;
}

.pricing-list a.btn.btn-cs-sec {
    margin-bottom: 15px;
}

.sideBarOpener {
    position: absolute;
    right: 0;
    top: 10px;
    width: 80px;
    height: calc(100vh - 20px);
    background-color: var(--header-dark);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.sidebarMenu {
    position: relative;
    height: 5px;
    width: 5px;
    background: var(--secText);
    border-radius: 50%;
}

.sidebarMenu:after {
    content: "";
    position: absolute;
    left: -12px;
    height: 5px;
    width: 5px;
    background: var(--secText);
    border-radius: 50%;
}

.sidebarMenu:before {
    content: "";
    position: absolute;
    right: -12px;
    height: 5px;
    width: 5px;
    background: var(--secText);
    border-radius: 50%;
}

ul.social-icon li {
    display: inline-block;
    margin-right: 10px;
}

ul.social-icon {
    margin: 10px 0 0 0;
    padding: 0;
}

ul.social-icon li a {
    color: var(--secText);
}

.page-Heading-cover {
    display: block;
    text-align: center;
}

.page-heading {
    position: relative;
    color: var(--primaryText);
    font-size: 60px;
    font-weight: 700;
    padding: 15px 0;
}

.page-heading:after {
    content: "";
    position: absolute;
    width: 50px;
    background: var(--secText);
    height: 2px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.page-sub-heading {
    display: block;
    color: var(--secText);
    font-size: 15px;
    font-weight: 600;
}

.timeline-item {
    position: relative;
    color: var(--primaryText);
    padding: 25px 0 20px 40px;
}

.timeline-item:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(100% + 10px);
    width: 1px;
    background: rgba(255, 255, 255, .08);
}

.timeline-item:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background: rgba(255, 255, 255, .08);
}

.timeline-time {
    display: inline-block;
    border: 2px solid var(--secText);
    padding: 5px 12px;
    font-size: 13px;
    border-radius: 3px;
    margin-left: -55px;
}

.timeline-time.highlighted {
    border-color: var(--btnPrimary);
}

.timeline-subheading {
    display: inline-block;
    font-size: 14px;
    margin-left: 10px;
    margin-bottom: 20px;
    color: var(--secText);
}

.timeline-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--primaryText);
}

.timeline-item p {
    font-size: 14px;
    line-height: 16px;
    color: var(--secText);
}

.timeline-wrap {
    margin: 15px 0 50px 0;
}

.resume-main-cover {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 50px;
}

.resume-first {
    width: calc(60% - 15px);
}

.resume-sec {
    width: calc(40% - 15px);
}

.progress {
    display: flex;
    height: 0.3rem;
    ;
    background-color: var(--header-dark)
}

.clear-test {
    color: var(--primaryText);
    margin-bottom: 2px;
}

span.pregressName {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.2px;
}

.pregressNum {
    color: var(--secText);
    font-size: 13px;
    float: right;
}

.skils-wrapper {
    margin: 15px 0 50px 0;
}

.progress-item {
    margin-bottom: 15px;
}

ul.knowledges {
    margin: 15px 0 50px 0;
    padding: 0;
}

ul.knowledges li {
    display: inline-block;
    background-color: var(--btnPrimary);
    border-radius: 3px;
    color: var(--primaryText);
    padding: 5px 10px;
    line-height: 1.2;
    margin: 3px 2px;
    font-size: 13px;
}

.certificate-item {
    background: rgba(255, 255, 255, .05);
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    border: 2px solid rgba(255, 255, 255, .07);
    border-radius: 6px;
    margin-bottom: 30px;
    -webkit-transition: all 2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.certificate-item:hover {
    transform: translateY(-5px);
}

.cetificates-wraper {
    margin-top: 15px;
}

.certi-logo {
    width: 120px;
    padding: 40px 15px;
    background: rgba(255, 255, 255, .1);
}

.certi-logo img {
    width: 100%;
}

.certi-content {
    padding: 0 15px;
}

.certi-title h4 {
    color: var(--primaryText);
    font-size: 16px;
    margin: 0 0 10px;
}

.certi-id span,
.certi-date span {
    font-size: 14px;
    color: var(--secText);
}

.conatct-link-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}

.contact-wrapper {
    margin: 50px 0;
}

.form-cover .section-title {
    margin-bottom: 25px;
}

.conatct-link {
    color: var(--btnPrimary);
    text-align: center;
    background-color: rgba(255, 255, 255, .08);
    border-color: rgba(255, 255, 255, .1);
    width: calc(33.3% - 15px);
    border-radius: 10px;
    font-size: 25px;
    padding: 25px 10px;
}

.conatct-link h4 {
    color: var(--primaryText);
    font-size: 18px;
    margin: 10px 0 0;
}

.form-cover form .col-6,
.form-cover form .col-12 {
    margin-bottom: 15px;
}

.form-cover form .form-control {
    background: transparent;
    color: var(--secText);
    border: 1px solid rgba(255, 255, 255, .09);
}

.form-cover {
    width: 60%;
    margin: 0 auto;
}

.form-control:focus {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 15%);
}

.siderbar {
    position: absolute;
    height: calc(100vh - 20px);
    width: 320px;
    top: 10px;
    right: -100%;
    background: var(--primaryBgc);
    padding: 20px;
    transition: all 0.3s linear;
    overflow-y: scroll;
    opacity: 0;
}

.siderbar.open {
    right: 80px;
    opacity: 1;
}

.sidebar-items {
    background: #39424c;
    padding: 20px;
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
    font-size: .9em;
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
    border-radius: 5px;
}

.sidebar-items .section-title h2 {
    font-size: 17px;
}

.sideBarOpener svg {
    color: #fff;
    font-size: 15px;
    height: 30px;
    width: 30px;
}

.sideBarOpener svg path {
    transition: all 0.3s linear;
}

.sidebar-items ul {
    margin: 0;
    padding: 0;
}

.sidebar-items ul li {
    color: #bbb;
    margin-top: 10px;
    font-size: 14px;
    line-height: 20px;
}

.mHeader-wrapper {
    position: absolute;
    height: 50px;
    background: #475c70;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    z-index: 5555;
    padding: 0 25px;
    align-items: center;
    justify-content: space-between;
    display: none;
}

.mobile-log {
    width: 40px;
    height: 40px;
    text-align: center;
    background-color: #000;
    overflow: hidden;
    border-radius: 5px;
}

.mobile-log img {
    width: 76%;
    object-fit: cover;
}

.mNavopenner {
    position: relative;
    height: 40px;
    width: 40px;
    transition: all 0.5s linear;
}

span.navLine {
    height: 3px;
    width: calc(100% - 10px);
    background: #fff;
    position: absolute;
    top: 50%;
    left: -5px;
    border-radius: 5px;
    opacity: 1;
    transition: all 0.3s linear;
}

.mNavopenner:before {
    content: "";
    height: 3px;
    width: calc(50% + 10px);
    position: absolute;
    top: calc(50% + 10px);
    left: 0;
    background: #fff;
    border-radius: 5px;
    transition: all 0.3s linear;
}

.mNavopenner:after {
    content: "";
    height: 3px;
    width: calc(50% + 10px);
    position: absolute;
    top: calc(50% - 10px);
    left: 0;
    background: #fff;
    border-radius: 5px;
    transition: all 0.3s linear;
}

.mNavopenner.open span.navLine {
    opacity: 0;
}

.mNavopenner.open:before {
    transform: rotate(45deg);
    top: 50%;
}

.mNavopenner.open:after {
    transform: rotate(-45deg);
    top: 50%;
}